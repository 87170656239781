
//*******************************
//* don't change this file
//* this file is auto generated
//*******************************

export const environment = {
  production: false,
  baseUrl: 'https://obo-nlp.staging.obodroid.app',
  auth: {
    loginUrl: 'https://obo-auth.staging.obodroid.app',
    clientId: 'user-zbx9gcwrjf9un2j8',
    redirectUri: 'https://obo-cms.staging.obodroid.app/callback'
  },
};
